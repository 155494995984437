import React from 'react';
import { BrowserRouter, Routes, Route, useLocation} from 'react-router-dom';
import {Provider as AppBridgeProvider} from '@shopify/app-bridge-react';
import {RoutePropagator as AppBridgeRoutePropagator} from '@shopify/app-bridge-react';

import ReactDOM from 'react-dom';
import {Provider, Loading} from '@shopify/app-bridge-react';

// export default function App() {
  
//   const location = useLocation();
//   console.log("config")
//   const config = {
//     // The client ID provided for your application in the Partner Dashboard.
//     apiKey: "6f2480f275fa1366aa401abc21ff9d0e",
//     // The host of the specific shop that's embedding your app. This value is provided by Shopify as a URL query parameter that's appended to your application URL when your app is loaded inside the Shopify admin.
//     // host: new URLSearchParams(location.search).get("host"),
//     host: 'YWRtaW4uc2hvcGlmeS5jb20vc3RvcmUvbGl2ZWx5LXNob3AtdGVzdA',
//     forceRedirect: true
//   };
  
//   return (
//     <AppBridgeProvider config={config}>
//       {/* <AppBridgeRoutePropagator location={location} /> */}
//       {/* <AppBridgeRoutePropagator /> */}
//       <BrowserRouter>
//       <AppBridgeRoutePropagator location={location} />
//       <Routes>
//         <Route path="/" element={<span>Hello world!</span>} />
//         { /* other routes */ }
//       </Routes>
//       </BrowserRouter>
//     </AppBridgeProvider>
//   );
// }



export default function App() {
  const config = {
    // The client ID provided for your application in the Partner Dashboard.
    apiKey: "6f2480f275fa1366aa401abc21ff9d0e",
    // The host of the specific shop that's embedding your app. This value is provided by Shopify as a URL query parameter that's appended to your application URL when your app is loaded inside the Shopify admin.
    // host: new URLSearchParams(location.search).get("host"),
    host: 'YWRtaW4uc2hvcGlmeS5jb20vc3RvcmUvbGl2ZWx5LXNob3AtdGVzdA',
    forceRedirect: true
  };
  return (
    <Provider config={config}>
      <Loading />
    </Provider>
  );
}

const root = document.createElement('div');
document.body.appendChild(root);
ReactDOM.createRoot(root).render(<App />);